import { ERROR_MSG } from './otherConstants';
export const isMobileNumberValid = (mobileNo) => {
  if (!mobileNo) return false;

  mobileNo = mobileNo?.trim();

  const isOnlyDigits = /^\d+$/.test(mobileNo);

  return mobileNo?.length === 10 && isOnlyDigits;
};

export const isEmail = (str) => {
  return str?.includes('@');
};

export const trimAndLowercase = (str) => {
  if (!str) return str;

  str = str?.trim();

  if (isEmail(str)) {
    str = str?.toLowerCase();
  }

  return str;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getQueryStringByFilterOptions = (filterOptions) => {
  let optionString = '';
  Object.keys(filterOptions).forEach((k) => {
    if (filterOptions[k] != null) optionString += `${k}=${filterOptions[k]}&`;
  });
  optionString = optionString.substring(0, optionString.length - 1);
  if (optionString.length > 0) {
    optionString = `?${optionString}`;
  }
  return optionString;
};
export const getErrorMessage = (error) => {
  return error?.response?.data?.message || ERROR_MSG;
};
