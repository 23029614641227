import React, { Component } from 'react';
import 'tachyons';
import _ from 'lodash';
import './styles.scss';
import TextInput from './TextInput';

class DropDownTextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //cursor: 0,
      label: '',
      data: [],
      tags: [],
      value: '',
      required: false,
      isUpdate: false,
    };
    this.searchResult = [];
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  componentWillMount() {
    const { label, data, value, tags, required, isUpdate, ...misc } =
      this.props;
    this.setState({
      label,
      data,
      tags,
      value,
      misc,
    });
    this.searchResult = data;
  }

  componentWillReceiveProps(np) {
    const { label, data, value, required, ...misc } = np;

    // if (data !== this.state.data) {
    //   this.searchResult = [];
    // }

    this.setState({
      label,
      data,
      value,
      required,
      misc,
    });
  }

  handleKeyDown(e) {
    const { cursor } = this.state;
    // if (e.keyCode === 38 && cursor > 0) {
    //   this.setState((prevState) => ({
    //     cursor: prevState.cursor - 1,
    //   }));
    // } else if (e.keyCode === 40 && cursor < this.searchResult.length - 1) {
    //   this.setState((prevState) => ({
    //     cursor: prevState.cursor + 1,
    //   }));
    // }
    if (e.keyCode === 13 && cursor >= 0) {
      // console.log(this.searchResult[cursor])
      e.preventDefault();
      this.selectOption(this.state.value);
      this.setState({ value: '' });
    }
  }

  selectOption(value) {
    const { setOption } = this.props;
    const { tags } = this.state;
    if (value && !tags.includes(value)) {
      this.setState({ tags: [...this.state.tags, value] });
      setOption([...this.state.tags, value]);
    }
  }

  filterData(text) {
    const { data } = this.state;
    this.searchResult = data.filter((c) =>
      c.tag.toLowerCase().includes(text.toLowerCase())
    );
    if (text !== '') {
      if (this.searchResult.length > 0 && this.searchResult[0].tag !== text)
        this.searchResult = [{ tag: text }, ...this.searchResult];
    }
    const { onChangeText } = this.props;
    this.setState({ value: text, cursor: 0 });
    onChangeText(text);
  }

  removeOption(value) {
    const { setOption } = this.props;
    const { tags } = this.state;
    const tempTask = tags.filter((tag) => tag !== value);
    this.setState({ tags: tempTask });
    //console.log(tags);
    setOption(tempTask);
  }

  renderSearchBox() {
    // const { cursor, canRemoveData } = this.state;
    return (
      <div className="search-box">
        {this.searchResult.map((s, i) => (
          <div key={i} className="search-entry">
            <div className="pointer" onClick={() => this.selectOption(s.tag)}>
              <p>{s.tag}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderTags() {
    const { tags, isUpdate } = this.state;
    return (
      <ul id="tags">
        {tags.map((tag, index) => (
          <li key={index} className="tag-div">
            <span className="tag-title">{tag}</span>
            {!isUpdate && (
              <span
                className="tag-close-icon"
                onClick={() => this.removeOption(tag)}
              >
                x
              </span>
            )}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { label, value, tags, misc, required, isUpdate } = this.state;
    const { disabled } = misc;
    return (
      <div>
        <div className="tags-input">
          {tags.length > 0 ? (
            this.renderTags()
          ) : (
            <p className=" ml2 mt2">Select some tags</p>
          )}
          <TextInput
            label={label}
            value={value}
            type="text"
            className="input"
            onTextChange={(text) => this.filterData(text)}
            required={required}
            onKeyDown={this.handleKeyDown}
            autoComplete={false}
            disabled={disabled}
          />
        </div>
        {!isUpdate && (
          <div style={{ position: 'relative' }}>
            {this.searchResult.length > 0 ? (
              this.renderSearchBox()
            ) : (
              <p className=" ml2 mt2">No tags found</p>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default DropDownTextInput;
