import React, { useState } from 'react';
// import Loader from '../../../common/components/Loader';
import './SolveAssignment.scss';
import moment from 'moment';
// import agent from '../../../agent';
import agent from '../../agent';
import { toast } from 'react-toastify';
// import { dateFormats } from '../../../constants/otherConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
// import { SET_USER } from '../../../constants/actionTypes';
// import { sendAmplitudeData } from '../../../common/amplitude';
// import {
//   INTERVIEWEE_WHATSAPP_NUMBER_ENTERED,
//   INTERVIEWER_SETUP_COMPLETED,
//   INTERVIEWER_WHATSAPP_NUMBER_ENTERED,
// } from '../../../constants/amplitudeConstants';
// import { getErrorMessage } from '../../../constants/helperFunctions';

const AppointmentTimingConfirmation = ({
  appointmentSlots,
  setActiveStep,
  isAppointmentee,
  selectedSlot,
  onWhatsappNumberSubmitted,
}) => {
  const [loading, setLoading] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const user = useSelector((state) => state.common.user);
  const dispatch = useDispatch();
  useEffect(() => {
    setWhatsappNumber(user.whatsappNumber);
  }, [user]);

  const updateUserWhatsappNumber = async () => {
    try {
      if (whatsappNumber.length < 10) {
        toast.error('Please enter a 10 digit whatsapp number');
        return;
      }

      setLoading(true);
      if (user.whatsappNumber !== whatsappNumber) {
        const data = { ...user, whatsappNumber };
        await agent.UserDetails.updateUserDetails({ user: data });
        // dispatch({ type: SET_USER, payload: data });
      }

      //   sendAmplitudeData(
      //     isAppointmentee
      //       ? INTERVIEWEE_WHATSAPP_NUMBER_ENTERED
      //       : INTERVIEWER_WHATSAPP_NUMBER_ENTERED,
      //     {
      //       whatsappNumber,
      //     }
      //   );
      if (!isAppointmentee) {
        // sendAmplitudeData(INTERVIEWER_SETUP_COMPLETED, {});
      }

      if (onWhatsappNumberSubmitted) {
        await onWhatsappNumberSubmitted();
      }
    } catch (err) {
      //   toast.error(getErrorMessage(err));
    }
    setLoading(false);
  };

  return (
    <div className="appointment-timing-confirmation">
      <p>
        Enter your Whatsapp number to allow your{' '}
        {isAppointmentee ? 'appointmenter' : 'junior (appointmentee)'} to reach
        out.
      </p>
      <div className="input-section">
        <img src="https://img.icons8.com/color/48/000000/whatsapp--v1.png" />
        <input
          value={whatsappNumber}
          onChange={(e) => setWhatsappNumber(e.target.value)}
        />
      </div>

      <button className="blue-btn mt4" onClick={updateUserWhatsappNumber}>
        Submit
      </button>
      {selectedSlot && (
        <div className="time-slots">
          <p className="w-50">Selected slot:</p>
          {/* <p className="time w-100 f6 b i">
            {`${moment(selectedSlot.date).format(
              dateFormats.dateTime
            )} - ${moment(selectedSlot.date).add(45, 'm').format('hh:mm A')}`}
          </p> */}
        </div>
      )}
      {appointmentSlots && (
        <div className="time-slots">
          <p className="w-50">Available time slot(s) entered:</p>
          <div className="flex items-center flex-wrap">
            {appointmentSlots.map((c, index) => (
              <p key={index} className="time">{`${moment(
                c.time,
                'hh:mm A'
              ).format('hh:mm A')} - ${moment(c.time, 'HH:mm:ss')
                .add(45, 'm')
                .format('hh:mm A')}`}</p>
            ))}
          </div>
        </div>
      )}
      <button className="blue-btn-light" onClick={() => setActiveStep(0)}>
        Edit
      </button>

      {/* {loading && <Loader />} */}
    </div>
  );
};

export default AppointmentTimingConfirmation;
